export const skills = [
  "Mobile SEO",
  "Keyword research",
  "On-page SEO",
  "Link building",
  "Google tools",
  "A/B testing",
  "Local SEO",
  "Social Skills",
  "Analytics Skills",
  "Data Skills",
  "Drive, Motivation & Adaptability",
  "Critical Thinking",
  "Research minded",
  "Communication",
  "Writing",
  "Flexibility",
  "Prioritization",
  "project management",
  "Decisions making",
  "Ability to use popular SEO tools",
  "Content Marketing",
];
